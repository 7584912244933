import React, {
  createContext,
  useCallback,
  useReducer,
  useContext
} from 'react'
import axios from 'axios'
import { useUserAuthentication, useUserContext } from '@gopeerproject/chuck'
import { assertContext as assert, redirectToCore, Sentry } from '@/utils'

import { TUser, TProfile } from '@/types'

const initialState = {
  isLoading: true,
  user: null,
  profile: null
}

type State = {
  isLoading: boolean
  user: TUser | null
  profile: TProfile | null
}

type StateValue = State[keyof State]

const reducer = (
  state: State,
  action:
    | { type: 'SET'; key: keyof State; value: StateValue }
    | { type: 'SET_MANY'; payload: Partial<State> }
): State => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        [action.key]: action.value
      }
    case 'SET_MANY':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

type AuthContext = State & {
  token?: string
  identify: () => void
  setToken: (token: string) => void
}
const AuthContext = createContext<AuthContext | null>(null)

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { mutate } = useUserAuthentication()
  const { token, setToken } = useUserContext()
  const [state, dispatch] = useReducer(reducer, initialState)

  const { isLoading, user, profile } = state

  const identify = useCallback(
    () =>
      mutate(
        {},
        {
          onSuccess: (data) => {
            setToken(data.token)

            dispatch({
              type: 'SET_MANY',
              payload: {
                user: data.user,
                profile: data.profile,
                isLoading: false
              }
            })

            try {
              Sentry.authenticate(data.user)
            } catch (e) {
              console.error('Sentry.authenticate', e)
            }
          },
          onError: (err) => {
            if (axios.isAxiosError(err)) {
              redirectToCore(`/auth?auth_redirect=${window.location.href}`)
            }
            console.error(err)
          }
        }
      ),
    [mutate, setToken, dispatch]
  )

  return (
    <AuthContext.Provider
      value={{ user, profile, token, isLoading, identify, setToken }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)!
  return assert(context, 'useAuthContext', 'AuthContextProvider')
}
