import React, { useCallback, useEffect } from 'react'
import { useLocalAudioToggle } from '@gopeerproject/web-kit'

import { IconButton } from '@gopeerproject/ui-kit'
import { MediaRestrictionStatus } from '@/data'
import { useMemberControlsQuery } from '../../../hooks'
import { useSocketMessageListener } from '@gopeerproject/chuck'
import { WSMsgPayload } from '../../MemberControls/hooks'

export const ToggleAudioButton = () => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()

  // useEffect(() => {
  //   ClassroomManager.updateParticipant({ audio: isAudioEnabled })
  // }, [isAudioEnabled])

  const { data } = useMemberControlsQuery()

  const { audio: audioRestrictionStatus } = data!

  useEffect(() => {
    if (
      audioRestrictionStatus === MediaRestrictionStatus.RESTRICTED &&
      isAudioEnabled
    ) {
      toggleAudioEnabled()
    }
  }, [isAudioEnabled, audioRestrictionStatus, toggleAudioEnabled])

  const onAudioUnrestricted = useCallback(
    /**@ref updateMemberControlsData */
    (payload: WSMsgPayload) => {
      if (
        payload?.audio === MediaRestrictionStatus.UNRESTRICTED &&
        !isAudioEnabled
      ) {
        toggleAudioEnabled()
      }
    },
    [toggleAudioEnabled, isAudioEnabled]
  )

  // @ts-expect-error chuck
  useSocketMessageListener('update:classroom_group', onAudioUnrestricted)
  return (
    <IconButton
      type='tertiary'
      size='lg'
      accent='neutral'
      onPress={toggleAudioEnabled}
      value={isAudioEnabled ? 'mic' : 'mic-off'}
      active
      disabled={audioRestrictionStatus === MediaRestrictionStatus.RESTRICTED}
    />
  )
}
