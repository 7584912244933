import { useContext, useReducer } from 'react'
import {
  useStyleSheet,
  createStyleSheet,
  View,
  Text,
  useThemeContext,
  Focusable,
  Icon
} from '@gopeerproject/ui-kit'

import { EditorContext } from '../contexts'

import { Popover, PopoverContent, PopoverTrigger, Tooltip } from '../components'

import {
  triggerShortcut,
  getObjectEditableProps,
  getObjectType
} from '../helpers'
import { ToolButton } from './ToolButton'
import { getSharedStyles } from './sharedStyles'
import { ThicknessAndStylePopover } from './ThicknessAndStylePopover'
import { Dot, FillAndStrokePopover } from './FillAndStrokePopover'
import { SK } from '../constants'
import { Feature, useClassroomPermissions } from '@/screens/classroom/contexts'

export const SelectedItemToolbar = () => {
  const styles = useStyleSheet(getSharedStyles)

  const {
    canvas,
    state: { color },
    setEditorState
  } = useContext(EditorContext)

  const { checkPermission } = useClassroomPermissions()
  const canDelete = checkPermission(Feature.SKETCH_ERASER)

  const [activeObject] = canvas.current?.getActiveObjects() ?? []
  const activeObjectProps = getObjectEditableProps(getObjectType(activeObject))

  const duplicate = () => {
    triggerShortcut('CtrlOrCmd+c')
    triggerShortcut('CtrlOrCmd+v')
  }

  const removeObject = () => {
    triggerShortcut('backspace')
  }

  const bringForward = () => {
    if (!activeObject) return
    activeObject.bringForward()
    canvas.current?.renderAll()
  }

  const sendBackwards = () => {
    if (!activeObject) return
    activeObject.sendBackwards()
    canvas.current?.renderAll()
  }

  const deselect = () => {
    canvas.current?.discardActiveObject()
    canvas.current?.renderAll()
  }

  type State = {
    moreTools: boolean
    thicknessAndStyle: boolean
    fillAndStroke: boolean
  }

  type Action =
    | {
        type: 'toggle'
        popover: keyof State
      }
    | {
        type: 'set'
        popover: keyof State
        value: boolean
      }

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      const { popover, type } = action
      switch (type) {
        case 'toggle':
          return { ...state, [popover]: !state[popover] }
        case 'set':
          return { ...state, [popover]: action.value }
        default:
          return state
      }
    },
    {
      moreTools: false,
      thicknessAndStyle: false,
      fillAndStroke: false
    }
  )

  return (
    <View style={styles.group}>
      <ToolButton icon='dismiss' onPress={deselect} />
      <View style={styles.divider} />

      {activeObjectProps?.strokeStyle || activeObjectProps?.strokeWidth ? (
        <Popover
          open={state.thicknessAndStyle}
          onOpenChange={(isOpen) =>
            dispatch({
              type: 'set',
              popover: 'thicknessAndStyle',
              value: isOpen
            })
          }
        >
          <PopoverTrigger>
            <Tooltip label='Thickness and style'>
              <ToolButton
                icon='line-style'
                onPress={() =>
                  dispatch({ type: 'toggle', popover: 'thicknessAndStyle' })
                }
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverContent>
            <ThicknessAndStylePopover activeObjectProps={activeObjectProps} />
          </PopoverContent>
        </Popover>
      ) : null}

      {activeObjectProps?.fill || activeObjectProps?.strokeColor ? (
        <Popover
          open={state.fillAndStroke}
          onOpenChange={(isOpen) =>
            dispatch({ type: 'set', popover: 'fillAndStroke', value: isOpen })
          }
        >
          <PopoverTrigger>
            <Tooltip label='Color'>
              <Dot
                fill={color.fill}
                stroke={color.border}
                circle
                onPress={() =>
                  dispatch({ type: 'toggle', popover: 'fillAndStroke' })
                }
              />
            </Tooltip>
          </PopoverTrigger>
          <PopoverContent>
            <FillAndStrokePopover activeObjectProps={activeObjectProps} />
          </PopoverContent>
        </Popover>
      ) : null}

      {activeObjectProps?.settingsModal ? (
        <ToolButton
          icon='settings'
          onPress={() => {
            setEditorState({ tool: SK.TOOLS.MATH })
          }}
        />
      ) : null}

      {Object.values(activeObjectProps ?? {}).some((v) => v) ? (
        <View style={styles.divider} />
      ) : null}

      <Tooltip label='Duplicate'>
        <ToolButton icon='copy' onPress={duplicate} />
      </Tooltip>

      {canDelete ? (
        <Tooltip label='Remove'>
          <ToolButton icon='delete' onPress={removeObject} />
        </Tooltip>
      ) : null}

      <Popover
        open={state.moreTools}
        onOpenChange={(isOpen) =>
          dispatch({ type: 'set', popover: 'moreTools', value: isOpen })
        }
      >
        <PopoverTrigger>
          <ToolButton
            icon='more-horizontal'
            onPress={() => dispatch({ type: 'toggle', popover: 'moreTools' })}
          />
        </PopoverTrigger>
        <PopoverContent>
          <SelectedItemMorePopover
            onBringForward={bringForward}
            onSendBackwards={sendBackwards}
          />
        </PopoverContent>
      </Popover>
    </View>
  )
}

const SelectedItemMorePopover: React.FC<{
  onBringForward: () => void
  onSendBackwards: () => void
}> = ({ onBringForward, onSendBackwards }) => {
  const styles = useStyleSheet(getStyles)
  const { size, color } = useThemeContext()

  return (
    <View style={styles.wrapper}>
      <Focusable
        onPress={onBringForward}
        style={styles.item}
        hoverStyle={styles.item__hover}
      >
        <Icon color={color.invert2.p100} size={size.I} name='arrow-up' />
        <Text size='md' type='body' weight='regular' style={styles.itemTitle}>
          Bring Forward
        </Text>
      </Focusable>
      <Focusable
        onPress={onSendBackwards}
        style={styles.item}
        hoverStyle={styles.item__hover}
      >
        <Icon color={color.invert2.p100} size={size.I} name='arrow-down' />
        <Text size='md' type='body' weight='regular' style={styles.itemTitle}>
          Send Backwards
        </Text>
      </Focusable>
      {/* <View style={styles.divider} /> */}
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  wrapper: {},
  item: {
    flexDirection: 'row',
    gap: size.C,
    padding: size.E,
    borderRadius: size.G
  },
  item__hover: {
    backgroundColor: color.invert2.p10
  },
  itemIcon: {},
  itemTitle: {
    flex: 1,
    paddingVertical: 1
  },
  divider: {
    backgroundColor: color.invert2.p4,
    height: 1,
    width: `calc(100% - ${2 * size.E}px)` as '100%',
    marginVertical: size.C,
    marginHorizontal: 'auto'
  }
}))
