import { useMutation, useQueryClient } from 'react-query'
import {
  useAPI,
  useChuckClientContext,
  useProfileContext,
  UserType,
  useSocketMessageListener
} from '@gopeerproject/chuck'
import { useCallback } from 'react'

import {
  WaitingRoomStatus,
  MediaRestrictionStatus,
  TMemberControls,
  MediaTypes
} from '@/data'
import { useClassroomEnv } from '../../contexts'
import { CacheKeys } from '@/constants'

const queryKey = CacheKeys.memberControls

export type WSMsgPayload = {
  appointmentId: string
  status?: WaitingRoomStatus
  video?: MediaRestrictionStatus
  audio?: MediaRestrictionStatus
  participants: {
    id: string
    status?: WaitingRoomStatus
    video?: MediaRestrictionStatus
    audio?: MediaRestrictionStatus
  }[]
}

type ManageAdmissionResponse = {
  participants: {
    id: string
    status: WaitingRoomStatus
  }[]
}

type AdmitAllResponse = {
  participants: {
    id: string
    status: WaitingRoomStatus
  }[]
}

type ManageMediaRestrictionsResponse = {
  participants: {
    id: string
    video: MediaRestrictionStatus
    audio: MediaRestrictionStatus
  }[]
}

type RestrictAudioAllResponse = {
  participants: {
    id: string
    audio: MediaRestrictionStatus
  }[]
}

const defaultWSMsgPayload: Omit<WSMsgPayload, 'appointmentId'> = {
  participants: []
}

// Updates the result of [member_controls, id] query in a way that it only updates the necessary fields
// Back-end only sends the fields that have changed, so we need to merge them with the previous data
// When user does a request that modifies data, they get an update through a response, other user(s) get an update through WS
const updateMemberControlsData =
  (data = defaultWSMsgPayload) =>
  (prevData: TMemberControls | undefined): TMemberControls => {
    const { status, video, audio, participants } = data
    const prevParticipantIds = new Set(prevData?.participants?.map((p) => p.id))
    const newParticipantIds = new Set(participants.map((p) => p.id))
    const allParticipantIds = Array.from(
      new Set([...prevParticipantIds, ...newParticipantIds])
    )

    const newParticipants = allParticipantIds.map((id) => {
      const prevParticipant = prevData?.participants?.find((p) => p.id === id)
      const newParticipant = participants.find((p) => p.id === id)

      return {
        id,
        ...prevParticipant,
        ...newParticipant
      }
    })

    return {
      ...prevData,
      ...(status ? { status } : {}),
      ...(video ? { video } : {}),
      ...(audio ? { audio } : {}),
      participants: newParticipants as TMemberControls['participants']
    } as TMemberControls
  }

export const useMemberAdmissionAction = () => {
  const { post } = useAPI()
  const { client } = useChuckClientContext()
  const { id } = useClassroomEnv()
  const queryClient = useQueryClient()

  return useMutation(
    ({
      participantId,
      status
    }: {
      participantId: string
      status: WaitingRoomStatus
    }) =>
      post({
        url: `/classroom_groups/${id}/manage_admission`,
        data: { participantId, status }
      }).then((res) => res.data as ManageAdmissionResponse),
    {
      onMutate: ({ participantId, status }) => {
        // Optimistically mark member as admitted
        const fn = updateMemberControlsData({
          participants: [{ id: participantId, status }]
        })
        queryClient.setQueryData([queryKey, id], fn)
      },
      onSuccess: (data) => {
        // Update member data with actual data on success
        queryClient.setQueryData([queryKey, id], updateMemberControlsData(data))
      },
      onError: (err, { participantId }) => {
        // Rollback on error
        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: [
              { id: participantId, status: WaitingRoomStatus.WAITING }
            ]
          })
        )

        client.captureException(err)
      }
    }
  )
}

export const useMemberAdmitAllAction = () => {
  const { post } = useAPI()
  const { client } = useChuckClientContext()
  const { id } = useClassroomEnv()
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      post({
        url: `/classroom_groups/${id}/admit_all`,
        data: {}
      }).then((res) => res.data as AdmitAllResponse),
    {
      onMutate: () => {
        // Optimistically mark all members as admitted
        const prevWaitingParticipants = queryClient
          .getQueryData<TMemberControls>([queryKey, id])!
          .participants.filter((p) => p.status === WaitingRoomStatus.WAITING)

        const optimisticParticipants = prevWaitingParticipants!.map((p) => ({
          ...p,
          status: WaitingRoomStatus.ADMITTED
        }))

        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: optimisticParticipants
          })
        )

        return { prevWaitingParticipants }
      },
      onSuccess: (data) => {
        // Update member data with actual data on success
        queryClient.setQueryData([queryKey, id], updateMemberControlsData(data))
      },
      onError: (err, _, context) => {
        // Rollback on error
        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: context?.prevWaitingParticipants ?? []
          })
        )

        client.captureException(err)
      }
    }
  )
}

export const useMemberMediaRestrictionAction = () => {
  const { post } = useAPI()
  const { client } = useChuckClientContext()
  const { id } = useClassroomEnv()
  const queryClient = useQueryClient()

  return useMutation(
    ({
      participantId,
      mediaType,
      status
    }: {
      participantId: string
      mediaType: MediaTypes
      status: MediaRestrictionStatus
    }) => {
      return post({
        url: `/classroom_groups/${id}/manage_media_restrictions`,
        data: {
          participantId,
          mediaType,
          status: status
        }
      }).then((res) => res.data as ManageMediaRestrictionsResponse)
    },
    {
      onMutate: ({ participantId, mediaType }) => {
        // Optimistically update member media restrictions

        const currentParticipant = queryClient
          .getQueryData<TMemberControls>([queryKey, id])!
          .participants.find((p) => p.id === participantId)!

        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: [
              {
                id: participantId,
                [mediaType]: reverseMediaRestrictionStatus(
                  currentParticipant[mediaType]
                )
              }
            ]
          })
        )

        return { [mediaType]: currentParticipant[mediaType] }
      },
      onSuccess: (data) => {
        // Update member data with actual data on success
        queryClient.setQueryData([queryKey, id], updateMemberControlsData(data))
      },
      onError: (err, { participantId, mediaType }, context) => {
        // Rollback on error
        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: [
              { id: participantId, [mediaType]: context?.[mediaType] }
            ]
          })
        )

        client.captureException(err)
      }
    }
  )
}

export const useMemberRestrictAudioAllAction = () => {
  const { post } = useAPI()
  const { client } = useChuckClientContext()
  const { id } = useClassroomEnv()
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      post({
        url: `/classroom_groups/${id}/restrict_audio_all`,
        data: {}
      }).then((res) => res.data as RestrictAudioAllResponse),
    {
      onMutate: () => {
        // Optimistically mute all members
        const prevUnrestrictedAudioParticipants = queryClient
          .getQueryData<TMemberControls>([queryKey, id])!
          .participants.filter(
            (p) => p.audio !== MediaRestrictionStatus.RESTRICTED
          )!

        const optimisticParticipants = prevUnrestrictedAudioParticipants.map(
          (p) => ({
            ...p,
            audio: MediaRestrictionStatus.RESTRICTED
          })
        )

        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: optimisticParticipants
          })
        )

        return { prevUnrestrictedAudioParticipants }
      },
      onSuccess: (data) => {
        // Update member data with actual data on success
        queryClient.setQueryData([queryKey, id], updateMemberControlsData(data))
      },
      onError: (err, _, context) => {
        // Rollback on error
        queryClient.setQueryData(
          [queryKey, id],
          updateMemberControlsData({
            participants: context?.prevUnrestrictedAudioParticipants ?? []
          })
        )

        client.captureException(err)
      }
    }
  )
}

export const useStudentJoinedRealtimeAction = () => {
  const { client } = useChuckClientContext()
  const { profile } = useProfileContext()

  const handler = useCallback(() => {
    if (profile?.type === UserType.TUTOR) {
      try {
        const audio = new Audio(
          'https://endeis.s3.us-west-2.amazonaws.com/audio/queue.mp3'
        )
        audio.play()
      } catch (err) {
        client.captureException(err)
      }
    }
  }, [client, profile])

  // @ts-expect-error chuck
  useSocketMessageListener('student_joined:classroom_group', handler)
}

export const useMemberControlsRealtimeActionsHandler = () => {
  const queryClient = useQueryClient()

  const handler = useCallback(
    (payload: WSMsgPayload) => {
      queryClient.setQueryData(
        [queryKey, payload.appointmentId],
        updateMemberControlsData(payload)
      )
    },
    [queryClient]
  )

  // @ts-expect-error chuck
  useSocketMessageListener('update:classroom_group', handler)
  useStudentJoinedRealtimeAction()
}

export function reverseMediaRestrictionStatus(status?: MediaRestrictionStatus) {
  if (status === MediaRestrictionStatus.RESTRICTED)
    return MediaRestrictionStatus.UNRESTRICTED
  return MediaRestrictionStatus.RESTRICTED
}

// video/audio: true - means unrestricted
export function mediaRestrictionStatusToBoolean(
  status?: MediaRestrictionStatus
) {
  if (status === MediaRestrictionStatus.RESTRICTED) return false
  return true
}
