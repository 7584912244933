import { createContext, useContext, useMemo, useState } from 'react'
import { useConversation } from '@gopeerproject/chuck'
import { assertContext } from '@gopeerproject/ui-kit'

type Visibility = 'public' | 'private'

const ConversationContext = createContext<
  | (Pick<
      ReturnType<typeof useConversation>,
      'data' | 'isLoading' | 'status'
    > & {
      visibility: Visibility
      setVisibility: React.Dispatch<React.SetStateAction<Visibility>>
    })
  | undefined
>(undefined)

export const ConversationContextProvider: React.FC<{
  children: React.ReactNode
  id: string
}> = ({ children, id }) => {
  const [visibility, setVisibility] = useState<Visibility>('public')
  const { data, isLoading, status } = useConversation(id)

  const value = useMemo(
    () => ({
      visibility,
      setVisibility,
      data,
      isLoading,
      status
    }),
    [visibility, data, isLoading, status]
  )

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  )
}

export const useConversationContext = () => {
  return assertContext(
    useContext(ConversationContext)!,
    'useConversationContext',
    'ConversationContextProvider'
  )
}
