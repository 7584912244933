const MAX_BACKGROUND_WIDTH = 1280
const MAX_BACKGROUND_HEIGHT = 768

export const resizeImage = async (image: HTMLImageElement): Promise<Blob> => {
  const width =
    image.width > image.height
      ? Math.round((image.width * MAX_BACKGROUND_HEIGHT) / image.height)
      : MAX_BACKGROUND_WIDTH

  const height =
    image.width > image.height
      ? MAX_BACKGROUND_HEIGHT
      : Math.round((image.height * MAX_BACKGROUND_WIDTH) / width)

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  if (!ctx) return Promise.reject(new Error('Canvas ctx error'))

  ctx.drawImage(image, 0, 0, width, height)

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error('canvas.toBlob error'))
          return
        }

        resolve(blob)
      },
      'image/jpeg',
      0.5
    )
  })
}

const blobToFile = (blob: Blob, name: string) => {
  return new File([blob], name, {
    type: blob.type,
    lastModified: Date.now()
  })
}

const createObjectURL = (blob: Blob) => {
  const crossPlatformUrl = window.URL || window.webkitURL
  return crossPlatformUrl.createObjectURL(blob)
}

export const readImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()

    reader.onerror = reject

    reader.onload = (event) => {
      if (!event.target || !event.target.result) {
        reject(new Error('FileReader onload error'))
        return
      }

      const blob = new Blob([event.target.result])
      const blobUrl = createObjectURL(blob)

      const image = new Image()
      image.src = blobUrl
      image.onload = () => {
        resizeImage(image).then((result) =>
          resolve(blobToFile(result, file.name))
        )
      }

      image.onerror = reject
    }

    reader.readAsArrayBuffer(file)
  })
}
