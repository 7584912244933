import React from 'react'
import {
  Button,
  createStyleSheet,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { GlobalMessage } from '@/components/GlobalMessage'
import { Sentry } from '@/utils'
import { useAuth } from '@/contexts'

interface Props {
  children: React.ReactNode
  panel: 'conversation' | 'member_controls' | 'standard_controls'
}

interface State {
  hasError: boolean
  errorRef: string
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, errorRef: '' }
  }

  static getDerivedStateFromError(): State {
    return { hasError: true, errorRef: '' }
  }

  componentDidCatch(error: Error) {
    const errorRef = Sentry.captureException(error)
    this.setState({ errorRef })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorView
          panel={this.props.panel}
          errorRef={this.state.errorRef}
          onTryAgain={() => this.setState({ hasError: false })}
        />
      )
    }

    return this.props.children
  }
}

const ErrorView: React.FC<{
  errorRef: string
  onTryAgain: () => void
  panel: 'conversation' | 'member_controls' | 'standard_controls'
}> = ({ errorRef, onTryAgain, panel }) => {
  const styles = useStyleSheet(getStyles)
  const { profile } = useAuth()
  return (
    <View style={styles.container} testID='sidebar-error-boundary-container'>
      <GlobalMessage
        emoji='😕'
        title={`Something went wrong with the ${panelToTitle(panel)}`}
        description={`Please try re-opening the ${panelToTitle(
          panel
        )} or contact our support. ${
          errorRef ? `(Error Ref: ${errorRef} ${profile?.type})` : ''
        }`}
      />
      <Button
        type='primary'
        centered
        size='lg'
        accent='default'
        onPress={onTryAgain}
        text='Try again'
        style={styles.button}
      />
    </View>
  )
}

const panelToTitle = (
  panel: 'conversation' | 'member_controls' | 'standard_controls'
) => {
  switch (panel) {
    case 'conversation':
      return 'Conversation panel'
    case 'member_controls':
      return 'Member Controls panel'
    case 'standard_controls':
      return 'Standard Controls panel'
    default:
      return 'Sidebar'
  }
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    width: 360,
    backgroundColor: color.bg.v1,
    overflow: 'hidden',
    paddingBottom: size.C,
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginTop: size.K
  }
}))
