import React, { Component, useMemo } from 'react'
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { fabric } from 'fabric'
import clone from 'lodash/cloneDeep'
import { useFileUpload } from '@gopeerproject/chuck'

import {
  ResizeObserverPony,
  convertSketchFile,
  getObjectEditableProps,
  getObjectType
} from './helpers'
import { Droppable, Upload } from './components'
import { SK, Files } from './constants'

import { EditorContext } from './contexts'
import { SketchShortcuts } from './SketchShortcuts'
import { useClassroomManagerRef } from '../../contexts'
import { useThemeContext } from '@gopeerproject/ui-kit'

import {
  Formula,
  FormulaGraphToolModal,
  Graph,
  registerCustomElements
} from './FormulaTools'
import { resizeImage } from '../Settings/imageUtils'

registerCustomElements()

class Editor extends Component {
  static contextType = EditorContext

  constructor(props) {
    super(props)

    this.state = {
      initialConfig: {}
    }
  }

  componentDidMount() {
    this.prevContext = clone(this.context)
    const {
      state: { color, line, tool },
      canvas
    } = this.context

    this.canvas = new fabric.Canvas(this.container, {
      width: this.wrapper.offsetWidth,
      height: this.wrapper.offsetHeight,
      preserveObjectStacking: true
    })
    window.__sketchpad = this.canvas

    this.canvas.freeDrawingBrush.color = `rgba(${color.border.slice(1, -1)}, 1)`
    this.canvas.freeDrawingBrush.width = line.width
    this.canvas.selectionColor = this.props.theme.selectionColor
    this.canvas.selectionBorderColor = this.props.theme.selectionBorderColor
    canvas.current = this.canvas

    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.cornerColor =
      this.props.theme.selectedItemCornerColor
    fabric.Object.prototype.cornerStrokeColor =
      this.props.theme.selectedItemBorderColor
    fabric.Object.prototype.borderColor =
      this.props.theme.selectedItemBorderColor
    fabric.Object.prototype.cornerSize = 7
    fabric.Object.prototype.cornerStyle = 'circle'

    const objs = [fabric.Object, fabric.Path]
    objs.forEach(this.setId)

    this.observer = new ResizeObserverPony(() => {
      this.resize()
    })

    this.observer.observe(this.wrapper)
    this.activate(tool)
    this.initListeners()

    this.props.attachEditor(this.canvas)
    this.props.loadFile()
    this.wrapper.focus()
  }

  componentDidUpdate(prevProps) {
    const { state: prev } = this.prevContext
    const { state: cur } = this.context

    if (prev.color.fill !== cur.color.fill) {
      this.updateFillColor(cur.color.fill)
    }

    if (prev.color.border !== cur.color.border) {
      this.updateBorderColor(cur.color.border)
    }

    if (prev.line.width !== cur.line.width) {
      this.updateLineWidth(cur.line.width)
    }

    if (prev.line.style !== cur.line.style) {
      this.updateLineStyle(cur.line.style)
    }

    if (prev.tool !== cur.tool) {
      this.activate(cur.tool)
    }

    this.prevContext = clone(this.context)

    if (this.props.active !== prevProps.active) {
      this.props.loadFile()
    }
  }

  componentWillUnmount() {
    this.observer.unobserve(this.wrapper)
    document.removeEventListener('paste', this.onImagePaste)
  }

  initListeners = () => {
    this.canvas
      .on('mouse:over', this.over)
      .on('mouse:out', this.out)
      .on('mouse:down', this.down)
      .on('mouse:move', this.move)
      .on('mouse:up', this.up)
      .on('selection:created', this.selectionCreated)
      .on('selection:updated', this.selection)
      .on('selection:cleared', this.selectionCleared)

    document.addEventListener('paste', this.onImagePaste)
    // .on('mouse:dblclick', (event) => {
    //   if (['Formula', 'Graph'].includes(event.target.type)) {
    //     this.canvas.discardActiveObject()
    //     this.context.openModal({
    //       name: 'formula-graph-modal',
    //       data: {
    //         id: event.target.id,
    //         latex: event.target.data,
    //         activeTab: event.target.type.toLowerCase()
    //       }
    //     })
    //   }
    // })
  }

  onImagePaste = (event) => {
    const clipboardData =
      event.clipboardData ||
      event.originalEvent?.clipboardData ||
      window.clipboardData

    // Check if the clipboard data contains an image and sketchpad is a focused element
    if (
      clipboardData &&
      clipboardData.types.includes('Files') &&
      this.wrapper.contains(document.activeElement)
    ) {
      const files = clipboardData.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        // Check if the pasted file is an image
        if (file.type.indexOf('image') !== -1) {
          // Handle the pasted image here
          const reader = new FileReader()
          reader.onload = (e) => {
            const imageDataUrl = e.target.result

            this.insertMedia(imageDataUrl, [40, 40])
          }

          resizeImage(file)
            .then((resizedFile) => {
              reader.readAsDataURL(resizedFile)
            })
            .catch((err) => {
              console.error('RESIZE ERROR', err)
              reader.readAsDataURL(file)
            })
        }
      }
    }
  }

  resize = () => {
    this.canvas.setWidth(this.wrapper.offsetWidth)
    this.canvas.setHeight(this.wrapper.offsetHeight)
  }

  setId = (v) => {
    v.prototype.toDatalessObject = (function (toDatalessObject) {
      return function () {
        return fabric.util.object.extend(toDatalessObject.call(this), {
          id: this.id
        })
      }
    })(v.prototype.toDatalessObject)
  }

  onDrop = (item, monitor) => {
    if (monitor) {
      const [file] = monitor.getItem().files
      this.upload(file)
    }
  }

  over = (e) => {
    const {
      state: { tool }
    } = this.context
    switch (tool) {
      case SK.TOOLS.ERASER:
        if (!e.target) break
        e.target.set({ opacity: 0.55 })
        this.canvas.renderAll()
        break
      default:
        break
    }
  }

  out = (e) => {
    const {
      state: { tool }
    } = this.context

    switch (tool) {
      case SK.TOOLS.ERASER:
        if (!e.target) break
        e.target.set({ opacity: 1 })
        this.canvas.renderAll()
        break
      default:
        break
    }
  }

  wheel = (opt) => {
    const delta = opt.e.deltaY
    let zoom = this.canvas.getZoom() + delta / 200
    zoom = zoom > 20 ? 20 : zoom < 0.1 ? 0.1 : zoom
    this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
    opt.e.preventDefault()
    opt.e.stopPropagation()
    this.canvas.fire('position:updated')
  }

  pinch = (evt) => {
    if (!this.pinchStartScale) this.pinchStartScale = this.canvas.getZoom()
    if (evt.touches && evt.touches.length === 2) {
      const delta = this.pinchStartScale * evt.scale
      this.canvas.zoomToPoint({ x: evt.layerX, y: evt.layerY }, delta)
      this.canvas.fire('position:updated')
    }
  }

  getLastPositions = (evt, TouchEvent) => {
    const clientX =
      TouchEvent && evt instanceof TouchEvent
        ? evt.touches[0].clientX
        : evt.clientX
    const clientY =
      TouchEvent && evt instanceof TouchEvent
        ? evt.touches[0].clientY
        : evt.clientY

    return { clientX, clientY }
  }

  down = (opt) => {
    const {
      state: { tool, color, line }
    } = this.context
    const evt = opt.e
    const pointer = this.canvas.getPointer(evt)
    let tmp
    const TouchEvent = Object.prototype.hasOwnProperty.call(
      window,
      'TouchEvent'
    )
      ? window.TouchEvent
      : undefined

    if (!(opt.target instanceof fabric.Textbox)) {
      this.canvas.forEachObject((v) =>
        v instanceof fabric.Textbox && v.isEditing ? v.exitEditing() : v
      )
    }

    if (TouchEvent && evt instanceof TouchEvent && evt.touches.length === 2) {
      if (tool === SK.TOOLS.PENCIL) this.canvas.isDrawingMode = false
      this.canvas.set({
        selection: false,
        isPinching: true
      })
      return
    }

    let textbox

    const { clientX, clientY } = this.getLastPositions(evt, TouchEvent)

    switch (tool) {
      case SK.TOOLS.ERASER:
        this.erase(opt.target)
        break
      case SK.TOOLS.TEXT: {
        if (opt.target instanceof fabric.Textbox) {
          opt.target.enterEditing()
          break
        }

        const defaultColor = `rgba(${SK.COLOR_PALETTE[2].slice(1, -1)}, 1)`

        textbox = new fabric.Textbox('Type something ...', {
          left: pointer.x,
          top: pointer.y,
          stroke:
            color.border === '(0,0,0)'
              ? defaultColor
              : `rgba(${color.border.slice(1, -1)}, 1)`,
          fill:
            color.fill === '(0,0,0)'
              ? defaultColor
              : `rgba(${color.fill.slice(1, -1)}, 1)`,
          strokeWidth: 1,
          fontFamily:
            'Helvetica Neue, -apple-system, Helvetica, Arial, sans-serif',
          id: `${SK.TOOLS.TEXT}-${Date.now()}`
        })
        this.canvas.add(textbox)
        textbox.enterEditing()
        break
      }
      case SK.TOOLS.RECT:
        tmp = new fabric.Rect({
          left: pointer.x,
          top: pointer.y,
          origX: pointer.x,
          origY: pointer.y,
          width: 0,
          height: 0,
          fill:
            color.fill === '(0,0,0)'
              ? 'rgba(255, 255, 255, .10)'
              : `rgba(${color.fill.slice(1, -1)}, .85)`,
          stroke:
            color.border === '(0,0,0)'
              ? 'rgba(255, 255, 255, .25)'
              : `rgba(${color.border.slice(1, -1)}, 1)`,
          strokeWidth: 1,
          id: `${SK.TOOLS.RECT}-${Date.now()}`
        })
        this.canvas.add(tmp)
        this.affectedElement = tmp
        break
      case SK.TOOLS.TRIANGLE:
        tmp = new fabric.Triangle({
          left: pointer.x,
          top: pointer.y,
          origX: pointer.x,
          origY: pointer.y,
          width: 0,
          height: 0,
          fill:
            color.fill === '(0,0,0)'
              ? 'rgba(255, 255, 255, .10)'
              : `rgba(${color.fill.slice(1, -1)}, .85)`,
          stroke:
            color.border === '(0,0,0)'
              ? 'rgba(255, 255, 255, .25)'
              : `rgba(${color.border.slice(1, -1)}, 1)`,
          strokeWidth: 1,
          id: `${SK.TOOLS.TRIANGLE}-${Date.now()}`
        })
        this.canvas.add(tmp)
        this.affectedElement = tmp
        break
      case SK.TOOLS.CIRCLE:
        tmp = new fabric.Ellipse({
          left: pointer.x,
          top: pointer.y,
          origX: pointer.x,
          origY: pointer.y,
          rx: 0,
          ry: 0,
          fill:
            color.fill === '(0,0,0)'
              ? 'rgba(255, 255, 255, .10)'
              : `rgba(${color.fill.slice(1, -1)}, .85)`,
          stroke:
            color.border === '(0,0,0)'
              ? 'rgba(255, 255, 255, .25)'
              : `rgba(${color.border.slice(1, -1)}, 1)`,
          strokeWidth: 1,
          id: `${SK.TOOLS.CIRCLE}-${Date.now()}`
        })
        this.canvas.add(tmp)
        this.affectedElement = tmp
        break
      case SK.TOOLS.LINE:
        tmp = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
          ...(line.style === 'dashed' ? { strokeDashArray: [5, 5] } : {}),
          strokeWidth: line.width,
          stroke:
            color.border === '(0,0,0)'
              ? 'rgba(255, 255, 255, .25)'
              : `rgba(${color.border.slice(1, -1)}, 1)`,
          id: `${SK.TOOLS.LINE}-${Date.now()}`
        })
        this.canvas.add(tmp)
        this.affectedElement = tmp
        break
      case SK.TOOLS.HAND:
        if (this.canvas.getActiveObject()) break
        this.canvas.set({
          isDragging: true,
          selection: false,
          lastPosX: clientX,
          lastPosY: clientY
        })
        this.canvas.setCursor('grabbing')
        break
      case SK.TOOLS.SELECT:
        break
      default:
        break
    }
  }

  move = (opt) => {
    const {
      state: { tool }
    } = this.context
    const evt = opt.e
    const pointer = this.canvas.getPointer(evt)
    const obj = this.affectedElement
    const TouchEvent = Object.prototype.hasOwnProperty.call(
      window,
      'TouchEvent'
    )
      ? window.TouchEvent
      : undefined

    if (
      this.canvas.isPinching ||
      (TouchEvent && evt instanceof TouchEvent && evt.touches.length === 2)
    ) {
      if (!this.canvas.isPinching) {
        this.canvas.set({
          isPinching: true,
          isDragging: false
        })
      }
      if (evt.touches.length === 2) this.pinch(evt)
      return
    }

    const { clientX, clientY } = this.getLastPositions(evt, TouchEvent)

    switch (tool) {
      case SK.TOOLS.RECT:
      case SK.TOOLS.TRIANGLE:
        if (!obj) break
        obj.set({
          left: obj.origX > pointer.x ? Math.abs(pointer.x) : obj.left,
          top: obj.origY > pointer.y ? Math.abs(pointer.y) : obj.top,
          width: Math.abs(obj.origX - pointer.x),
          height: Math.abs(obj.origY - pointer.y)
        })
        this.canvas.renderAll()
        break
      case SK.TOOLS.CIRCLE:
        if (!obj) break
        obj.set({
          left: obj.origX > pointer.x ? Math.abs(pointer.x) : obj.left,
          top: obj.origY > pointer.y ? Math.abs(pointer.y) : obj.top,
          rx: Math.abs(obj.origX - pointer.x) / 2,
          ry: Math.abs(obj.origY - pointer.y) / 2
        })
        this.canvas.renderAll()
        break
      case SK.TOOLS.LINE:
        if (!obj) break
        obj.set({ x2: pointer.x, y2: pointer.y })
        this.canvas.renderAll()
        break
      case SK.TOOLS.HAND:
        if (!this.canvas.isDragging) break
        this.canvas
          .set({
            viewportTransform: this.canvas.viewportTransform.map((v, i) =>
              i === 4
                ? v + clientX - this.canvas.lastPosX
                : i === 5
                ? v + clientY - this.canvas.lastPosY
                : v
            ),
            lastPosX: clientX,
            lastPosY: clientY
          })
          .requestRenderAll()

        this.canvas
          .getObjects()
          .filter((object) => ['Graph', 'Formula'].includes(object.type))
          .forEach((object) => object.positionElement())
        break
      case SK.TOOLS.SELECT:
        break
      default:
        break
    }
  }

  up = () => {
    const {
      state: { tool }
    } = this.context
    if (this.canvas.isPinching) {
      if (tool === SK.TOOLS.PENCIL) this.canvas.isDrawingMode = true
      this.pinchStartScale = undefined
      this.canvas.set({ isPinching: false })
      return
    }

    switch (tool) {
      case SK.TOOLS.RECT:
        this.handleUp(SK.TOOLS.RECT)
        this.release()
        break
      case SK.TOOLS.TRIANGLE:
        this.handleUp(SK.TOOLS.TRIANGLE)
        this.release()
        break
      case SK.TOOLS.CIRCLE:
        this.handleUp(SK.TOOLS.CIRCLE)
        this.release()
        break
      case SK.TOOLS.LINE:
        this.handleUp(SK.TOOLS.LINE)
        // here just recently added element needs to be deactivated, rest of them should already be such
        break
      case SK.TOOLS.TEXT:
        this.release()
        break
      case SK.TOOLS.HAND:
        if (!this.canvas.isDragging) break
        this.canvas
          .set({ isDragging: false, selection: true })
          .forEachObject((obj) => obj.setCoords())
        this.canvas.fire('position:updated')
        break
      case SK.TOOLS.SELECT:
        break
      default:
        break
    }
  }

  selection = (e) => {
    const {
      state: { color, line },
      setEditorState
    } = this.context

    if (e.selected.length > 1) {
      // TODO
      // following causes multiple selection to change color
      // of selected components
      // return setEditorState({
      //   color: { fill: '(0,0,0)', border: '(0,0,0)' },
      //   line: { style: 'normal', width: 1 }
      // })
      return
    }

    const [target] = e.selected

    const isGraph = target instanceof Graph

    const hasStroke = [
      fabric.Path,
      fabric.Textbox,
      fabric.Line,
      fabric.Rect,
      fabric.Triangle,
      fabric.Ellipse,
      Formula
    ].reduce((acc, v) => acc || target instanceof v, false)
    const hasFill = [
      fabric.Textbox,
      fabric.Rect,
      fabric.Ellipse,
      fabric.Triangle
    ].reduce((acc, v) => acc || target instanceof v, false)
    const hasWidth = [fabric.Path, fabric.Line].reduce(
      (acc, v) => acc || target instanceof v,
      false
    )

    const patch = {
      color: {},
      line: {}
    }

    if (hasFill && target.fill && !isGraph) {
      const color = `(${target.fill
        .match(/rgba\((.+),.+\)/)[1]
        .replace(/ /g, '')})`
      const opacity = target.fill.match(/rgba\(.+,(.+)\)/)[1].replace(/ /g, '')
      patch.color.fill =
        color === '(255,255,255)' && opacity === '.10' ? '(0,0,0)' : color
    }
    if (hasStroke && target.stroke) {
      const color = `(${target.stroke
        .match(/rgba\((.+),.+\)/)[1]
        .replace(/ /g, '')})`
      const opacity = target.stroke
        .match(/rgba\(.+,(.+)\)/)[1]
        .replace(/ /g, '')
      patch.color.border =
        color === '(255,255,255)' && opacity === '.25' ? '(0,0,0)' : color
    }

    if (hasWidth) {
      patch.line.style = target.strokeDashArray ? 'dashed' : 'normal'
      patch.line.width = target.strokeWidth
    }

    setEditorState({
      color: { ...color, ...patch.color },
      line: { ...line, ...patch.line }
    })
  }

  selectionCreated = (e) => {
    const { initialConfig } = this.state
    const {
      state: { color, line }
    } = this.context

    this.setState({
      initialConfig: {
        ...initialConfig,
        color: { ...color },
        line: { ...line }
      }
    })
    this.selection(e)
  }

  selectionCleared = () => {
    const { initialConfig } = this.state
    const { setEditorState } = this.context

    setEditorState({
      ...initialConfig
    })
  }

  handleUp = () => {
    const obj = this.affectedElement
    this.affectedElement = null
    if (!obj) return
    obj.setCoords()
    if (this.props.classroomManagerRef.current.getBinding())
      this.props.classroomManagerRef.current
        .getBinding()
        .modify({ target: obj })
  }

  release = () => {
    const { setEditorState } = this.context
    setEditorState({ tool: SK.TOOLS.HAND })
    this.canvas
      .set({ selection: true })
      .forEachObject((obj) => (obj.selectable = true))
  }

  updateFillColor = (color) => {
    color =
      color === '(0,0,0)'
        ? 'rgba(255, 255, 255, .25)'
        : `rgba(${color.slice(1, -1)}, 1)`

    const actives = this.canvas.getActiveObjects()
    if (!actives.length) return
    actives.forEach((active) => {
      const hasFill = getObjectEditableProps(getObjectType(active)).fill

      if (!hasFill) return
      active.set({
        fill: color
      })
    })

    // TODO
    // do we actually need this?
    this.canvas.renderAll().fire('object:modified', { target: actives })
  }

  updateBorderColor = (color) => {
    color =
      color === '(0,0,0)'
        ? 'rgba(255, 255, 255, .25)'
        : `rgba(${color.slice(1, -1)}, 1)`

    this.canvas.freeDrawingBrush.color = color

    const actives = this.canvas.getActiveObjects()
    if (!actives.length) return
    actives.forEach((active) => {
      const hasStroke = getObjectEditableProps(
        getObjectType(active)
      ).strokeColor

      if (!hasStroke) return
      active.set({
        stroke: color
      })
    })

    this.canvas.renderAll().fire('object:modified', { target: actives })
  }

  updateLineWidth = (width) => {
    this.canvas.freeDrawingBrush.width = width
    const actives = this.canvas.getActiveObjects()
    if (!actives.length) return
    actives.forEach((active) => {
      if (!getObjectEditableProps(getObjectType(active)).strokeWidth) return
      active.set({ strokeWidth: width })
    })

    this.canvas.renderAll().fire('object:modified', { target: actives })
  }

  updateLineStyle = (style) => {
    this.canvas.freeDrawingBrush.strokeDashArray =
      style === 'normal' ? undefined : [5, 5]
    const actives = this.canvas.getActiveObjects()
    if (!actives.length) return
    actives.forEach((active) => {
      if (!getObjectEditableProps(getObjectType(active)).strokeStyle) return
      active.set({
        strokeDashArray: style === 'normal' ? undefined : [5, 5]
      })
    })

    this.canvas.renderAll().fire('object:modified', { target: actives })
  }

  activate = (tool) => {
    switch (tool) {
      case SK.TOOLS.PENCIL:
        this.activatePencil()
        break
      case SK.TOOLS.LINE:
        this.activateLine()
        break
      case SK.TOOLS.RECT:
        this.activateRect()
        break
      case SK.TOOLS.TRIANGLE:
        this.activateTriangle()
        break
      case SK.TOOLS.CIRCLE:
        this.activateCircle()
        break
      case SK.TOOLS.TEXT:
        this.activateText()
        break
      case SK.TOOLS.IMAGE:
        this.activateImage()
        break
      case SK.TOOLS.SELECT:
        this.activateSelect()
        break
      case SK.TOOLS.HAND:
        this.activateHand()
        break
      case SK.TOOLS.ERASER:
        this.activateEraser()
        break
      default:
        break
    }
  }

  activatePencil = () => {
    this.select({
      isDrawingMode: true,
      selection: false,
      exitEditing: true
    })
  }

  activateLine = () => {
    this.select({
      isDrawingMode: false,
      selection: false,
      exitEditing: true,
      selectable: false
    })
  }

  activateRect = () => {
    this.select({
      isDrawingMode: false,
      selection: false,
      exitEditing: true,
      selectable: false
    })
  }

  activateTriangle = () => {
    this.select({
      isDrawingMode: false,
      selection: false,
      exitEditing: true,
      selectable: false
    })
  }

  activateCircle = () => {
    this.select({
      isDrawingMode: false,
      selection: false,
      exitEditing: true,
      selectable: false
    })
  }

  activateText = () => {
    this.select({
      isDrawingMode: false,
      selection: false,
      selectable: false
    })
  }

  activateImage = () => {
    this.select({
      isDrawingMode: false,
      exitEditing: true
    })

    this.pickFile()
  }

  activateSelect = () => {
    this.select({
      isDrawingMode: false,
      exitEditing: true,
      selection: true,
      selectable: true
    })
  }

  activateHand = () => {
    this.select({
      isDrawingMode: false,
      exitEditing: true,
      selectable: true
    })
  }

  activateEraser = () => {
    this.select({
      isDrawingMode: false,
      selection: true,
      exitEditing: true,
      keepActive: true
    })
  }

  select = (options = {}) => {
    const { canvas } = this
    if ('isDrawingMode' in options) canvas.isDrawingMode = options.isDrawingMode
    if ('selection' in options) canvas.selection = options.selection
    if ('exitEditing' in options)
      canvas.forEachObject((v) =>
        v instanceof fabric.Textbox && v.isEditing ? v.exitEditing() : v
      )
    if ('selectable' in options)
      canvas.forEachObject((obj) => (obj.selectable = options.selectable))
    if (!('keepActive' in options)) canvas.discardActiveObject()
    // Fabric js canvas playground is flickering on safari
    // Link to solution -> https://github.com/fabricjs/fabric.js/issues/6112#issuecomment-597593171
    canvas.clearContext(canvas.getSelectionContext())
    canvas.requestRenderAll()
  }

  pickFile = () => {
    const { setEditorState } = this.context
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = [...SK.FILE_TYPES.PDF, ...SK.FILE_TYPES.IMAGES].join(',')
    input.onchange = (event) => {
      const [file] = event.target.files
      event.target.value = null

      if (!file) return
      this.upload(file)
    }

    input.click()
    setEditorState({ tool: SK.TOOLS.SELECT })
  }

  upload = (file) => {
    if (file.size > Files.MAX_FILE_SIZE) {
      return this.props.handleChange({ oversize: [file] })
    }
    if (![...SK.FILE_TYPES.PDF, ...SK.FILE_TYPES.IMAGES].includes(file.type))
      return this.props.handleChange({ invalid: [file] })

    convertSketchFile(file, this.props.handleChange).then((files) =>
      files.forEach(([file, pos = [10, 10]]) =>
        this.props
          .upload(
            file,
            {
              type: file.type,
              name: file.name,
              ...(this.props.active ? { parent: this.props.active } : {})
            },
            {
              onUploadProgress: ({ progress }) => {
                this.props.handleChange({ progress })
              }
            }
          )
          .then(
            ({ data: url }) => {
              this.insertMedia(url, pos)
              this.props.handleChange({ progress: undefined })
            },
            (err) => {
              if (err.data === 'Invalid File Type')
                this.props.handleChange({
                  invalid: [file],
                  progress: undefined
                })
            }
          )
      )
    )
  }

  onFormulaGraphSave = () => {
    this.activateSelect()
  }

  insertMedia = (url, pos = [10, 10]) => {
    const { canvas } = this
    pos = [
      pos[0] - canvas.viewportTransform[4],
      pos[1] - canvas.viewportTransform[5]
    ]
    fabric.Image.fromURL(url, (img) => {
      img.set({
        left: pos[0],
        top: pos[1],
        id: `${SK.TOOLS.IMAGE}-${Date.now()}`
      })

      if (
        img.height &&
        img.width &&
        img.height <= SK.MEDIA_SCALE_HEIGHT &&
        img.width <= SK.MEDIA_SCALE_HEIGHT
      ) {
        // do not scale
      } else if (img.height >= img.width)
        img.scaleToHeight(SK.MEDIA_SCALE_HEIGHT, false)
      else img.scaleToWidth(SK.MEDIA_SCALE_HEIGHT, false)

      canvas.add(img).setActiveObject(img)
      this.context.setEditorState({ tool: SK.TOOLS.HAND })
    })
  }

  erase = (target) => {
    const tmp = this.canvas.getActiveObjects()
    if (tmp.length) {
      tmp.forEach((obj) => this.canvas.remove(obj))
      this.canvas.discardActiveObject()
      this.canvas.requestRenderAll()
    } else if (target) {
      this.canvas.remove(target)
    }
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Droppable
          ref={(elem) => (this.dropzone = elem)}
          item={{ type: NativeTypes.FILE }}
          onDrop={this.onDrop}
          style={{ width: '100%', height: '100%' }}
        >
          {(isOver) => {
            return (
              <React.Fragment>
                <div
                  id='sketchpad-wrapper'
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    outline: 'none'
                  }}
                  ref={(elem) => (this.wrapper = elem)}
                  tabIndex={1}
                >
                  <canvas ref={(elem) => (this.container = elem)} />
                </div>
                {isOver && <Upload />}

                <SketchShortcuts canvas={this.canvas} />

                <FormulaGraphToolModal onSave={this.onFormulaGraphSave} />
              </React.Fragment>
            )
          }}
        </Droppable>
      </DndProvider>
    )
  }
}

const EditorWrapper = (props) => {
  const uploadFile = useFileUpload()
  const { color } = useThemeContext()
  const classroomManagerRef = useClassroomManagerRef()

  const editorTheme = useMemo(() => {
    return {
      selectionColor: color.primary.ghost,
      selectionBorderColor: color.primary.solid,
      selectedItemCornerColor: color.white.p100,
      selectedItemBorderColor: color.primary.solid
    }
  }, [color])
  return (
    <Editor
      {...props}
      upload={uploadFile}
      theme={editorTheme}
      classroomManagerRef={classroomManagerRef}
    />
  )
}
export { EditorWrapper as Editor }
