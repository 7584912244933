import { useEffect, useState, useCallback } from 'react'
import { useEventCreation, isClient } from '@gopeerproject/chuck'
import { useMediaContext, useRoomState } from '@gopeerproject/web-kit'
import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'
import {
  ClassroomEnv,
  ClassroomStep,
  useClassroomDispatch,
  useClassroomEnv
} from '../../contexts'
import { useAuth } from '@/contexts'
import { TProfile } from '@/types'

import { Intro } from './Intro'
import { Devices } from './Devices'
import { useClassroomQuery } from '../../hooks'
import { HeaderPlaceholder } from '@/components'
import { useClassroomManager } from '../../hooks/useClassroomManager'

const STEPS = {
  INTRO: 'INTRO',
  DEVICES: 'DEVICES'
}

export const Onboarding = () => {
  const styles = useStyleSheet(getStyles)
  const { env } = useClassroomEnv()
  const { profile } = useAuth()
  const [step, setStep] = useState(STEPS.INTRO)
  const { connect } = useMediaContext()
  const roomState = useRoomState()
  const classroomDispatch = useClassroomDispatch()
  const { data } = useClassroomQuery()
  const { mutate } = useEventCreation()
  const { initialize } = useClassroomManager()

  const classroom = data!
  const { twilioToken } = classroom
  const { type: ptype } = profile!

  const next = useCallback(() => {
    switch (step) {
      case STEPS.INTRO:
        return setStep(STEPS.DEVICES)

      case STEPS.DEVICES:
        initialize()
        switch (env) {
          case ClassroomEnv.PLAYGROUND:
            classroomDispatch({
              type: 'set_step',
              payload: ClassroomStep.READY
            })
            return
          case ClassroomEnv.CLASSROOM:
            if (classroom.type === 'group')
              mutate({
                location: 'classroom',
                action: getJoinActionName(ptype),
                externalId: classroom.id
              })
            connect(twilioToken)
            return
          default:
        }
    }
  }, [
    step,
    initialize,
    env,
    classroomDispatch,
    classroom.type,
    classroom.id,
    mutate,
    ptype,
    connect,
    twilioToken
  ])

  useEffect(() => {
    if (roomState !== 'disconnected')
      classroomDispatch({
        type: 'set_step',
        payload: ClassroomStep.READY
      })
  }, [roomState, classroomDispatch])

  return (
    <>
      <HeaderPlaceholder />
      <View style={styles.container}>
        {step === STEPS.INTRO && <Intro next={next} />}
        {step === STEPS.DEVICES && <Devices next={next} />}
      </View>
    </>
  )
}

function getJoinActionName(profileType: TProfile['type']) {
  switch (true) {
    case isClient(profileType):
      return 'student_join'
    default:
      return `${profileType}_join`
  }
}

const getStyles = createStyleSheet(() => ({
  container: { flexGrow: 1, justifyContent: 'center', alignItems: 'center' }
}))
