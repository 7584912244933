import { useClassroomState } from '../../contexts'
import { Conversation } from '../Conversation'
import { MemberControls } from '../MemberControls'
import { StandardControls } from './StandardControls'
import { ErrorBoundary } from './ErrorBoundary'

export const Sidebar = () => {
  const { isSidebarOpen, sidebarPanel } = useClassroomState()

  switch (true) {
    case isSidebarOpen && sidebarPanel === 'conversation':
      return (
        <ErrorBoundary panel='conversation'>
          <Conversation />
        </ErrorBoundary>
      )
    case isSidebarOpen && sidebarPanel === 'member_controls':
      return (
        <ErrorBoundary panel='member_controls'>
          <MemberControls />
        </ErrorBoundary>
      )
    case isSidebarOpen && sidebarPanel === 'standard_controls':
      return (
        <ErrorBoundary panel='standard_controls'>
          <StandardControls />
        </ErrorBoundary>
      )
    default:
      return null
  }
}
