import * as Y from 'yjs'
import { fabric } from 'fabric'
import { createMutex } from 'lib0/mutex.js'
import throttle from 'lodash/throttle'

const SK = {
  TOOLS: {
    PATH: 'PATH'
  }
}

export class YFabricBinding {
  constructor(type, fabric, awareness, metadata, manager) {
    this.type = type
    this.metadata = metadata
    this.doc = type.doc
    this.fabric = fabric
    this.awareness = awareness
    this.manager = manager
    this.undoManager = new Y.UndoManager(this.type)
    this._mux = createMutex()
    this.load(this.type.toJSON())
    this.type.observe(this.updateType)
    if (this.awareness) this.awareness.on('change', this.updateAwareness)
    this.metadata.observe(this.updateMetadata)
    this.destroyed = false
  }

  enable = () => {
    this.fabric
      .on('object:added', this.add)
      .on('object:modified', this.modify)
      .on('object:removed', this.remove)
      .on('position:updated', this.position)
      .on('mouse:move', this.move)
  }

  disable = () => {
    return this.fabric
      .off('object:added', this.add)
      .off('object:modified', this.modify)
      .off('object:removed', this.remove)
      .off('position:updated', this.position)
      .off('mouse:move', this.move)
  }

  load = (snapshot) => {
    this.disable()
    // cleanup first
    // Needed when custom elements are used and cleanup is needed
    this.fabric.getObjects().forEach((object) => this.fabric.remove(object))

    snapshot = { version: '4.2.0', objects: Object.values(snapshot) }
    this.fabric.loadFromJSON(snapshot, () => {
      if (this.destroyed) return
      this.updateMetadata()
      this.enable()
    })
  }

  updateType = (event) => {
    this._mux(() => {
      const objects = this.fabric.getObjects()
      if (!objects.length && Object.keys(this.type.toJSON()).length)
        return this.load(this.type.toJSON())
      for (let [key, value] of event.changes.keys.entries()) {
        const { action } = value
        const existing = Array.prototype.find.call(objects, (v) => key === v.id)
        const incoming = this.type.get(key)

        switch (action) {
          case 'add':
            fabric.util.enlivenObjects([incoming], ([enlivened]) => {
              if (this.destroyed) return
              this.fabric.add(enlivened)
            })
            break
          case 'update':
            existing
              .set(incoming)
              .set({ left: incoming.left, top: incoming.top })
              .setCoords()

            if (['Graph', 'Formula'].includes(incoming.type)) {
              existing.fire('modified', incoming)
            }

            this.fabric.requestRenderAll()
            break
          case 'delete':
            this.fabric.remove(existing)
            break
        }
      }
    })
  }

  updateMetadata = () => {
    this._mux(() => {
      const metadata = this.metadata.toJSON()

      const hasViewportTransform =
        metadata.viewportTransform &&
        metadata.viewportTransform.every(
          (v) => typeof v === 'number' && !Number.isNaN(v)
        )

      if (!hasViewportTransform) {
        delete metadata.viewportTransform
      }

      this.fabric
        .set(
          Object.keys(metadata).reduce(
            (acc, key) =>
              metadata[key] !== undefined
                ? { ...acc, [key]: metadata[key] }
                : acc,
            {}
          )
        )
        .renderAll()

      if (!metadata.viewportTransform) return

      this.fabric.zoomToPoint(
        {
          x: metadata.viewportTransform[4],
          y: metadata.viewportTransform[5]
        },
        metadata.zoom
      )
    })
  }

  updateAwareness = (e, origin) => {
    if (
      origin.roomname &&
      origin.roomname !== this.manager.editors.sketch?.provider?.id
    ) {
      return
    }

    const participants = [...e.added, ...e.removed, ...e.updated].filter(
      (clientId) => clientId !== this.doc.clientID
    )

    participants.forEach(this.updateRemoteCursor)
  }

  updateRemoteCursor = (clientId) => {
    const awareness = this.awareness.getStates().get(clientId)
    if (!awareness) {
      if (this.manager.observers['awareness']) {
        this.manager.observers['awareness'].forEach((cb) => cb(clientId, null))
      }
      return
    }
    const participant = awareness.user
    const cursor = awareness.cursor
    if (!cursor) return
    const data = { ...participant, ...this.transform(cursor) }

    if (this.manager.observers['awareness']) {
      this.manager.observers['awareness'].forEach((cb) => cb(clientId, data))
    }
  }

  transform = (data) => {
    const zoom = this.fabric.getZoom()
    const viewportTransform = this.fabric.viewportTransform
    const down = false

    return {
      top: data.y * zoom + viewportTransform[5] - 10,
      left: data.x * zoom + viewportTransform[4],
      down
    }
  }

  move = throttle((e) => {
    this.awareness.setLocalStateField('cursor', {
      x: e.absolutePointer.x,
      y: e.absolutePointer.y
    })
  }, 50)

  position = () => {
    this._mux(() => {
      this.doc.transact(() => {
        if (this.destroyed) return

        this.metadata.set('zoom', this.fabric.getZoom())
        this.metadata.set('viewportTransform', this.fabric.viewportTransform)
        this.metadata.set('lastPosX', this.fabric.lastPosX)
        this.metadata.set('lastPosY', this.fabric.lastPosY)
      })
    })
  }

  add = (e) => {
    this._mux(() => {
      this.doc.transact(() => {
        this.format(e, 'add').forEach((object) =>
          this.type.set(object.id, object)
        )
      })
    })
  }

  modify = (e) => {
    this._mux(() => {
      this.doc.transact(() => {
        this.format(e, 'modify').forEach((object) => {
          this.type.set(object.id, object)
        })
      })
    })
  }

  remove = (e) => {
    this._mux(() => {
      this.doc.transact(() => {
        this.format(e, 'remove').forEach((object) => {
          this.type.delete(object.id)
        })
      })
    })
  }

  format = (event, operation) => {
    if (this.destroyed) return []
    let objects = event.target._objects
    if (!objects)
      objects = Array.isArray(event.target) ? event.target : [event.target]
    objects = objects.map((v) =>
      v.type === 'path' && !v.id
        ? v.set({ id: `${SK.TOOLS.PATH}-${Date.now()}` })
        : v
    )

    if (operation === 'remove') return objects
    const current = this.fabric.toDatalessObject().objects
    return objects
      .map((v) => current.find((c) => c.id === v.id))
      .filter((v) => !!v)
  }

  undo = () => {
    this.undoManager.undo()
  }

  redo = () => {
    this.undoManager.redo()
  }

  destroy = () => {
    this.type.unobserve(this.updateType)
    this.metadata.unobserve(this.updateMetadata)
    if (this.awareness) {
      const awareness = this.awareness.getStates()
      if (this.manager.observers['awareness']) {
        this.manager.observers['awareness'].forEach((cb) =>
          awareness.forEach((value, key) => cb(key, null))
        )
      }
      this.awareness.off('change', this.updateAwareness)
    }
    this.disable()
    this.type = null
    this.fabric = null
    this.undoManager.destroy()

    this.destroyed = true
  }
}
