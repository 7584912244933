import {
  Appointment,
  useAPI,
  useChuckClientContext
} from '@gopeerproject/chuck'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useClassroomEnv } from '../contexts'
import { TClassroom } from '@/types'
import { TMemberControls } from '@/data'
import { CacheKeys } from '@/constants'

export const useClassroomUpdate = () => {
  const { id } = useClassroomEnv()
  const { patch } = useAPI()
  const { client } = useChuckClientContext()
  const queryClient = useQueryClient()

  return useMutation(
    (
      body: Partial<{
        overtime: number
        rating: number
        review: string
        duration: number
      }>
    ) =>
      patch({ url: `/appointments/${id}`, data: body }).then(
        (res) => res.data as Appointment
      ),
    {
      onSuccess: (appt) => {
        const {
          overtime,
          duration,
          participants,
          id,
          // @TODO add this to chuck types
          // @ts-expect-error
          standard
        } = appt
        queryClient.setQueryData(
          [CacheKeys.classrooms, id],
          // TClassroom
          (prevClassroom: TClassroom | undefined): TClassroom => {
            if (!prevClassroom) {
              throw new Error('invalid classroom update')
            }

            return {
              ...prevClassroom,
              overtime,
              duration,
              participants: participants.reduce(
                (acc, participant) => ({
                  ...acc,
                  [participant.id]: {
                    name: participant.name,
                    image: participant.image,
                    type: participant.type
                  }
                }),
                {}
              ),
              standard
            }
          }
        )
      },
      onError: (err) => {
        client.captureException(err)
      }
    }
  )
}

export const useClassroomQueryById = (
  id: string,
  options?: {
    onSuccess: (data: TClassroom) => void
  }
) => {
  const { get } = useAPI()
  const { client } = useChuckClientContext()

  return useQuery(
    [CacheKeys.classrooms, id],
    () =>
      get({ url: `/classrooms/${id}` }).then((res) => res.data as TClassroom),
    {
      onError: (err) => {
        client.captureException(err)
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
      ...options
    }
  )
}

export const useClassroomQuery = (options?: {
  onSuccess: (data: TClassroom) => void
}) => {
  const { id } = useClassroomEnv()

  return useClassroomQueryById(id, options)
}

export const useMemberControlsQueryById = (
  id: string,
  options?: { onSuccess: (data: TMemberControls) => void }
) => {
  const { get } = useAPI()
  const { client } = useChuckClientContext()

  return useQuery({
    queryKey: [CacheKeys.memberControls, id],
    queryFn: () =>
      get({ url: `/classroom_groups/${id}` }).then(
        (res) => res.data as TMemberControls
      ),
    onError: (err) => {
      client.captureException(err)
    },
    ...options
  })
}
export const useMemberControlsQuery = (options?: {
  onSuccess: (data: TMemberControls) => void
}) => {
  const { id } = useClassroomEnv()

  return useMemberControlsQueryById(id, options)
}
