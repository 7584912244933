import React, { useRef, useMemo, useEffect } from 'react'
import { Messages as UIMessages } from '@gopeerproject/ui-kit'
import {
  useConversationUpdate,
  useMessages,
  useProfileContext,
  useSkills,
  useAwarenessContext
} from '@gopeerproject/chuck'
import { useConversationContext } from '../../contexts/ConversationContext'

export const Main: React.FC<{ thread: string }> = ({ thread }) => {
  const fetching = useRef(false)
  // const [preview, setPreview] = useState(null)
  const { profile } = useProfileContext()
  const { mutate: updateConversation } = useConversationUpdate()
  const { findByValue, isLoading: areSkillsLoading } = useSkills()
  const { setFocusedConversation } = useAwarenessContext()
  const { visibility, data: conversation, status } = useConversationContext()

  const {
    data,
    isLoading: areMessagesLoading,
    hasNextPage,
    fetchNextPage
  } = useMessages(thread, visibility)

  const isConversationLoading = status !== 'success'
  const isLoading = isConversationLoading || areMessagesLoading

  const { id: pid } = profile!

  const messages = useMemo(
    () =>
      (isLoading || areSkillsLoading
        ? []
        : (data?.pages ?? []).flatMap((page) => page.records)
      ).map((message) =>
        message.type === 'appointment'
          ? {
              ...message,
              data: {
                // @ts-expect-error
                ...message.data,
                // @ts-expect-error
                subject: findByValue(message.data.subject).name,
                // @ts-expect-error
                participantStatus: message.data.participants.find(
                  ({ id }: { id: string }) => id === pid
                )?.status
              }
            }
          : message
      ),
    [isLoading, areSkillsLoading, data, findByValue, pid]
  )

  const onEndReached = async () => {
    if (!fetching.current && hasNextPage) {
      fetching.current = true
      await fetchNextPage()
      fetching.current = false
    }
  }

  useEffect(() => {
    if (isLoading) return

    const { id: conversationId, unseen } = conversation!

    setFocusedConversation({ id: conversationId, visibility })

    if (unseen[visibility]) {
      updateConversation({
        id: conversationId,
        user: pid,
        unseen: {
          ...unseen,
          [visibility]: 0
        }
      })
    }

    return () => {
      setFocusedConversation({ id: null, visibility: null })
    }
  }, [
    conversation,
    isLoading,
    pid,
    setFocusedConversation,
    updateConversation,
    visibility
  ])

  const download = async (url: string, name: string) => {
    const link = document.createElement('a')

    link.setAttribute('href', url)
    link.setAttribute('download', name)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      {/* {!!preview && (
        <FilePreview file={preview} onClose={() => setPreview(null)} />
      )} */}

      <UIMessages
        isLoading={isLoading as any}
        data={messages}
        onEndReached={onEndReached}
        participants={conversation?.participants ?? []}
        onAppointmentPreview={(id) => {
          console.log(id)
          // history.push(`/appointments/${id}`)
        }}
        onFilePreview={(data) => {
          console.log(data)
          // setPreview(data)
        }}
        onFileDownload={({ data, name }) => {
          download(data, name)
        }}
      />
    </>
  )
}
