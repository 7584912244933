import { useCallback, useMemo } from 'react'
import { ClassroomManager, Tab } from '../utils/ClassroomManager'
import {
  useClassroomDispatch,
  useClassroomEnv,
  useClassroomManagerRef,
  useClassroomState
} from '../contexts'
import { useAuth } from '@/contexts'
import { isClient } from '@gopeerproject/chuck'

export const useClassroomManager = () => {
  const classroomManagerRef = useClassroomManagerRef()
  const classroomDispatch = useClassroomDispatch()
  const { profile, token } = useAuth()
  const { env, id } = useClassroomEnv()
  const { activeTabId } = useClassroomState()

  // Private tabs logic
  const onTabsChange = useCallback(() => {
    const prevActiveTabId = activeTabId

    // eslint-disable-next-line prefer-const
    let { tabs: nextTabs, activeTabId: nextActiveTabId } =
      classroomManagerRef.current!.getTabs()

    if (!isClient(profile!.type)) {
      classroomDispatch({
        type: 'set_tabs',
        payload: {
          tabs: nextTabs,
          activeTabId: nextActiveTabId
        }
      })
      return
    }

    const isPublicOrOwned = (tab: Tab) =>
      !tab.owner || tab.owner === profile!.id

    nextTabs = nextTabs.filter(isPublicOrOwned)

    const setActiveTabToHome = () =>
      classroomDispatch({
        type: 'set_tabs',
        payload: {
          tabs: nextTabs,
          activeTabId: null
        }
      })

    // if next tab is home
    if (nextActiveTabId === null) {
      setActiveTabToHome()
      return
    }

    // if next tab is file check if it is public or owned
    const nextActiveTab = nextTabs.find((tab) => tab.id === nextActiveTabId)

    if (!nextActiveTab) {
      //stay on same tab
      return
    }

    // if its public or owned, set it
    if (isPublicOrOwned(nextActiveTab)) {
      classroomDispatch({
        type: 'set_tabs',
        payload: {
          tabs: nextTabs,
          activeTabId: nextActiveTabId
        }
      })
      return
    }

    // if its not public or owned, set the previous tab
    classroomDispatch({
      type: 'set_tabs',
      payload: {
        tabs: nextTabs,
        activeTabId: prevActiveTabId
      }
    })
  }, [activeTabId, classroomDispatch, classroomManagerRef, profile])

  const initialize = useCallback(() => {
    classroomManagerRef.current = new ClassroomManager({
      id,
      user: profile!,
      token: token!,
      env
    })

    // @ts-ignore
    window.cm = classroomManagerRef.current

    classroomManagerRef.current.observe('tabs', onTabsChange)
  }, [classroomManagerRef, env, id, onTabsChange, profile, token])

  const destroy = useCallback(() => {
    classroomManagerRef.current?.destroy()
  }, [classroomManagerRef])

  return useMemo(
    () => ({
      initialize,
      destroy
    }),
    [initialize, destroy]
  )
}
