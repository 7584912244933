import { useEffect, useMemo } from 'react'
import { RouterProvider } from 'react-router-dom'

import { createRouter } from './routes'
import { useAuth } from '@/contexts'
import {
  createStyleSheet,
  Loader,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

export const App = () => {
  const styles = useStyleSheet(getStyles)

  const { isLoading, identify, setToken } = useAuth()

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const token = search.get('token')
    const next = search.get('next')

    if (!token) {
      identify()
      return
    }

    setToken(token)
    if (next) {
      history.replaceState(history.state, next) // uses browser history
    }
  }, [identify, setToken])

  const router = useMemo(() => {
    if (isLoading) {
      return null
    }
    return createRouter()
  }, [isLoading])

  return (
    <div className={`app app--dark`}>
      <View style={styles.container}>
        <div id='modal' style={{ zIndex: 999 }}></div>
        {/* <div id='tooltip' style={{ zIndex: 999 }}></div> */}
        {isLoading ? <Loader full /> : <RouterProvider router={router!} />}
      </View>
    </div>
  )
}

const getStyles = createStyleSheet(({ color }) => ({
  container: {
    backgroundColor: color.bg.v1,
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    position: 'relative'
  }
}))
